import type { BrandingCategories, BrandingTypographyElement, TypographyBranding } from '~/types/branding'
import type { FontFamily } from '~/types/design-system'

export function useBrandingTypography() {
  const { brandings } = storeToRefs(useStoreBranding())

  const extractTypography = (
    typographyCategory: BrandingCategories,
    typographyName: string,
    fontFamily: FontFamily
  ): TypographyBranding => {
    if (!brandings.value) return {} as TypographyBranding

    const category = brandings.value[typographyCategory]
    const element = category![typographyName as keyof typeof category]
    const { id, weight, size, color, alignment, text } = element as unknown as BrandingTypographyElement

    return {
      id,
      fontWeight: weight,
      fontSize: size,
      color,
      textAlign: getTextAlignment(alignment),
      fontFamily,
      text
    }
  }

  return { extractTypography }
}
