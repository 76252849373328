import type { FontFamily } from '@wewiink/design-system/dist/types/styling.js'
import type { TypographyTextAlign } from '@wewiink/design-system/dist/types/typography.js'
import type { BrandingGridCard, BrandingTypographyElement } from '~/types/branding'

export const getTextAlignment = (alignment: number): TypographyTextAlign => {
  let textAlign: TypographyTextAlign = 'left'
  if (alignment === 2) {
    textAlign = 'center'
  } else if (alignment === 3) {
    textAlign = 'right'
  }

  return textAlign
}

export const extractBrandingTypography = (rawBranding: BrandingTypographyElement, fontFamily: FontFamily) => {
  const { id, weight, size, color, alignment, text } = rawBranding

  return {
    id,
    fontWeight: weight,
    fontSize: size,
    color,
    textAlign: getTextAlignment(alignment),
    fontFamily,
    text
  }
}

export const extractBrandingCard = (rawBranding: BrandingGridCard) => {
  const { id, background_color, hover_background_color, radius, border_color } = rawBranding
  return {
    id,
    backgroundColor: background_color,
    hoverBackgroundColor: hover_background_color,
    borderColor: border_color,
    borderRadius: radius
  }
}
